import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Services from "../components/expanded/servicesExt";
const About = () => {
  return (
    <div>
      <Navbar />
      <Services/>
      <Footer />
    </div>
  );
};
export default About;
